import React from "react"
import { Route, Routes } from "react-router-dom"
import { CallbackPage } from "./pages/CallbackPage"

import "./App.css"
import ChatPage from "./pages/ChatPage";

function App() {
    console.log('process.env.REACT_APP_AUTH0_CALLBACK_URL',process.env.REACT_APP_AUTH0_CALLBACK_URL)
  return (
    <Routes>
      <Route path="/" element={<ChatPage />} />
      <Route path="/callback" element={<CallbackPage />} />
    </Routes>
  )
}

export default App
