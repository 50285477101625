export const headerLabels = {
  HOME: "Home",
  ASSISTANT: "ESG assistant",
}
export const messageLabels = {
  UNDERSTANDING_QUESTION: "Understanding question",
  WRITE_SOMETHING: "Write something...",
  SHARE_WITH_FRIENDS: "Share with a friend",
  SEND: "Send",
  NEW_TOPIC: "New topic",
  CREATE_NEW_TOPIC: "Create new topic",
  ASK_QUESTIONS: "Ask follow-up question"
}
export const modalLabels = {
  SOURCES: "Sources",
  PAGE: "Page",
}

export const modalShareWithFriendsLabels = {
  TITLE: "Share with friends",
  TA: "Theo Assistant",
}
export const chatLabels = {
  TITLE: "Get help with all your CSRD questions",
  DESCRIPTION:
    'Navigate the Corporate Sustainability Reporting Directive (CSRD) effortlessly with our ESG Assistant. It simplifies the complexities of everything from Scope 1, 2, and 3 emissions to the concept of double materiality. Please note, the assistant may occasionally make mistakes.',
}
export const dummy = {
  TITLE: "What is CSRD?",
  DESCRIPTION: "This question was asked 2023-10-10 at 12:12."
}
export const headerLinks = {
  LOGIN: 'Log in',
  SIGNUP: 'Sign up'
}