export const getFormattedDateTimeNow = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm: string | number = today.getMonth() + 1; // Months start at 0!
    let dd: string | number  = today.getDate();
    const h = today.getHours()
    let m: string | number  = today.getMinutes();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    if (m < 10) {
        m = "0" + m;
    }
    const formattedToday = `${dd}-${mm}-${yyyy} ${h}:${m}`;
    return formattedToday
}
export function truncate(str: string, max: number) {
    return str.length > max ? str.substr(0, max-1) + '…' : str;
}