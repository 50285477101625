import React from "react"
import {
  AssessmentIcon,
  ComplianceIcon,
  DataLakeIcon,
  DisclosureIcon,
  ESGIcon,
  LogoIcon,
  SupportIcon,
  UserTheoIcon,
} from "./icons"
import "./styles.css"

const data = [
  {
    icon: <ESGIcon />,
    title: "ESG Assistant",
  },
  {
    icon: <ComplianceIcon />,
    title: "Compliance as a Service",
  },
  {
    icon: <AssessmentIcon />,
    title: "Double Materiality Assessment",
  },
  {
    icon: <DisclosureIcon />,
    title: "Disclosure reporting",
  },
  {
    icon: <DataLakeIcon />,
    title: "Sustainability Data Lake",
  },
]
const dataFooter = [
  {
    icon: <SupportIcon />,
    title: "Support",
  },
  {
    icon: <UserTheoIcon />,
    title: "Log in",
  },
]
type TheoSidebarTypeProps = {
  openTHModal: () => void
}

const TheoSidebar = ({ openTHModal }: TheoSidebarTypeProps) => {
  return (
    <div className="theo-sidebar">
      <div className="theo-sidebar-logo">
        <LogoIcon />
      </div>
      <div className="theo-sidebar-main">
      <div
        className="theo-sidebar-menu-items"
        onClick={openTHModal}
        role="button"
      >
        {data?.map((menuItem, index) => (
          <div
            key={index}
            className={
              index === 0
                ? "theo-sidebar-menu-item__active theo-sidebar-menu-item"
                : "theo-sidebar-menu-item"
            }
          >
            <div className="theo-sidebar-menu-icon">{menuItem.icon}</div>
            <div className="theo-sidebar-menu-text">{menuItem.title}</div>
          </div>
        ))}
      </div>
      <div className="theo-sidebar-footer">
        {dataFooter?.map((menuItem, index) => (
          <div key={index} className="theo-sidebar-menu-item" onClick={openTHModal}>
            <div className="theo-sidebar-menu-icon">{menuItem.icon}</div>
            <div className="theo-sidebar-menu-text">{menuItem.title}</div>
          </div>
        ))}
      </div>
    </div>
    </div>
  )
}
export default TheoSidebar
