// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-user {
    display: flex;
    flex-direction: column-reverse;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 80%;
    margin-bottom: 16px;

}
.message-user-text {
    background-color: #F2F4F7;
    font-size: 13px;
    font-weight: 400;
    padding: 12px;
    border-radius: 8px;
    line-height: 18px
}
.message-user-info {
    display: flex;
    align-items: center;
    margin-top: 16px;
}
.message-user-title {
    margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/userCard/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,uBAAkB;IAAlB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;;AAEvB;AACA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB;AACJ;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".message-user {\n    display: flex;\n    flex-direction: column-reverse;\n    width: fit-content;\n    max-width: 80%;\n    margin-bottom: 16px;\n\n}\n.message-user-text {\n    background-color: #F2F4F7;\n    font-size: 13px;\n    font-weight: 400;\n    padding: 12px;\n    border-radius: 8px;\n    line-height: 18px\n}\n.message-user-info {\n    display: flex;\n    align-items: center;\n    margin-top: 16px;\n}\n.message-user-title {\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
