import React, {useState} from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Modal from "react-modal"
import "./styles.css"
import { BotIcon, SearchIcon, CloseIcon } from "../../assets/icons"
import { SourceDocuments } from "../../pages/ChatPage"
import ReferencesModalContent from "./referencesModalContent/ReferencesModalContent"
import { modalLabels } from "../../utils/labels"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "300px",
    padding: "0",
    minHeight: "300px",
    width: "60%",
    maxHeight: "calc(100vh - 53px)",
  },
  overlay: {
    zIndex: "10",
  },
}

type BotCardProps = {
  text: string
  user: string
  questions?: string[]
  sourceDocuments: SourceDocuments[]
  date?: string
  sendMessage: (body: string) => void
  loading: boolean | undefined
}

const BotCard = ({
  text,
  questions,
  sourceDocuments,
  user,
  date,
  sendMessage,
  loading,
}: BotCardProps) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [isQuestionBlockShow, setIsQuestionBlockShow] = useState(true)
  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
  return (
    <>
      <div className="message-bot">
        <div className="message-bot-text">
          {loading && date === "" ? (
              <div className="spinner-position"><div className="loader" /></div>
          ) : (
            <span> {text}</span>
          )}
          {sourceDocuments?.length > 0 && (
            <div role="button" className="ref-link" onClick={openModal}>
              {sourceDocuments?.length} {modalLabels.SOURCES} <SearchIcon />
            </div>
          )}
        </div>
        {date && (
          <div className="bot-info">
            <div className="bot-title">
              <div className="name">{user}</div>
              <div className="date">{date}</div>
            </div>
            <BotIcon />
          </div>
        )}
      </div>
      {isQuestionBlockShow && questions && questions?.length > 0 && (
        <div className="flex-column-centered tags-wrapper mb-16">
          <CloseIcon className="close-btn" onClick={() => setIsQuestionBlockShow(false)}/>
          <p>Here are some suggestions to expand on this topic</p>
          {questions?.map((link, index) => {
            return (
              <div
                key={index}
                className="message-link"
                role="button"
                onClick={() => sendMessage(link.trim())}
              >
                {link}
              </div>
            )
          })}
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <ReferencesModalContent
          closeModal={closeModal}
          sourceDocuments={sourceDocuments}
        />
      </Modal>
    </>
  )
}
export default BotCard
