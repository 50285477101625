import React from "react"
import { SourceDocuments } from "../../../pages/ChatPage"
import "./styles.css"
import PageContent from "./PageContent"
import { CloseIcon } from "../../../assets/icons"
import { modalLabels } from "../../../utils/labels"

type ReferencesModalContentType = {
  sourceDocuments: SourceDocuments[]
  closeModal: () => void
}

const ReferencesModalContent = ({
  sourceDocuments,
  closeModal,
}: ReferencesModalContentType) => {
  return (
    <div>
      <div className="modal-header">
        <div className="modal-header-title">
          {sourceDocuments?.length} {modalLabels.SOURCES}
        </div>
        <div
          onClick={closeModal}
          role="button"
          className="modal-content-source-close-btn"
        >
          <CloseIcon />
        </div>
      </div>
      <div className="modal-content">
        <div className="modal-content-source-main-title">
          {modalLabels.SOURCES}
        </div>
        <div className="modal-content-wrapper">
          {sourceDocuments.map((source, index) => {
            return (
              <div className="modal-content-source" key={index}>
                <div className="modal-content-source-title">
                  {modalLabels.PAGE} {source?.metadata?.page} ·{" "}
                  {source?.metadata?.document_name}
                </div>
                <PageContent content={source?.pageContent} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default ReferencesModalContent
