import React from "react"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Modal from "react-modal"
import { CloseIcon, ListIcon } from "../../assets/icons"
import vinnova from "../../assets/img/vinnova_logo.png"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    width: "50%",
    height: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: "10",
  },
}

type InfoModalPropsType = {
  modalIsOpen: boolean
  closeModal: () => void
}

const InfoModal = ({ modalIsOpen, closeModal }: InfoModalPropsType) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="modal-header">
        <div className="modal-header-title">How does this work?</div>
        <div
          onClick={closeModal}
          role="button"
          className="modal-content-source-close-btn"
        >
          <CloseIcon />
        </div>
      </div>
      <div className="info-modal-content">
        <div className="info-modal">
          <p>
            Sustainability reporting exists to promote transparency, measurability and actionability, Theo aims to make CSRD easier to navigate and understand. We provide our ESG assistant for free as part of that mission.
          </p>
          <ul>
            <li className="info-modal-list-item">
              <div className="info-modal-list-item-icon">
                <ListIcon />
              </div>
              <div className="info-modal-list-item-text">
                Citations - Theo always shows what data we trained our assistant on in relation to the answer you receive.
              </div>
            </li>
            <li className="info-modal-list-item">
              <div className="info-modal-list-item-icon">
                <ListIcon />
              </div>
              <div className="info-modal-list-item-text">
                Verification - Even though we and OpenAI strive towards being accurate, the technology is new and ever evolving. ChatGPT can make mistakes, consider checking important information.
              </div>
            </li>
          </ul>
        </div>
        <div className="info-modal-logos">
          <img src={vinnova} alt="vinnova-logo"/>
        </div>
        <div className="info-modal-bottom-text">
          Our ESG-bot uses ChatGPT 3.5 and 4, which we’ve trained on official CSRD data and other reputable sources.
        </div>
      </div>
    </Modal>
  )
}
export default InfoModal