// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content-source {
  border: 1px dashed #d0d5dd;
  margin-top: 10px;
  border-radius: 8px;
  padding: 5px 12px 16px 12px;
}
.modal-content {
    padding: 1.2rem;
}
.modal-content-wrapper {
    overflow: scroll;
}
.modal-content-source-read-btn {
  color: #6b7c5e;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.modal-content-source-descr {
    color: #151C2C;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 0;
    line-height: 18px;
    white-space: pre-line;
}
.modal-content-source-title {
    color: #151C2C;
    font-size: 13px;
    font-weight: 600;
    padding: 10px 0;
    line-height: 18px;
}
.modal-content-source-main-title {
    color: #151C2C;
    font-size: 15px;
    font-weight: 600;
    padding: 0 3px 3px 3px;
    line-height: 18px;
}
.modal-header-title {
    color: #151C2C;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 3px;
    line-height: 18px;
}
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D0D5DD;
    padding: 5px 16px;
}
.modal-content-source-close-btn {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/botCard/referencesModalContent/styles.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,kBAAkB;EAClB,2BAA2B;AAC7B;AACA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gCAAgC;IAChC,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB","sourcesContent":[".modal-content-source {\n  border: 1px dashed #d0d5dd;\n  margin-top: 10px;\n  border-radius: 8px;\n  padding: 5px 12px 16px 12px;\n}\n.modal-content {\n    padding: 1.2rem;\n}\n.modal-content-wrapper {\n    overflow: scroll;\n}\n.modal-content-source-read-btn {\n  color: #6b7c5e;\n  font-size: 13px;\n  font-weight: 500;\n  cursor: pointer;\n}\n.modal-content-source-descr {\n    color: #151C2C;\n    font-size: 13px;\n    font-weight: 400;\n    padding: 10px 0;\n    line-height: 18px;\n    white-space: pre-line;\n}\n.modal-content-source-title {\n    color: #151C2C;\n    font-size: 13px;\n    font-weight: 600;\n    padding: 10px 0;\n    line-height: 18px;\n}\n.modal-content-source-main-title {\n    color: #151C2C;\n    font-size: 15px;\n    font-weight: 600;\n    padding: 0 3px 3px 3px;\n    line-height: 18px;\n}\n.modal-header-title {\n    color: #151C2C;\n    font-size: 18px;\n    font-weight: 600;\n    padding: 10px 3px;\n    line-height: 18px;\n}\n.modal-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid #D0D5DD;\n    padding: 5px 16px;\n}\n.modal-content-source-close-btn {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
