export { ReactComponent as UserIcon } from "./UserIcon.svg"
export { ReactComponent as BotIcon } from "./BotIcon.svg"
export { ReactComponent as CloseIcon } from "./CloseIcon.svg"
export { ReactComponent as SearchIcon } from "./SearchIcon.svg"
export { ReactComponent as ShareIcon } from "./ShareIcon.svg"
export { ReactComponent as InfoIcon } from "./InfoIcon.svg"
export { ReactComponent as ListIcon } from "./ListIcon.svg"
export { ReactComponent as OpenAILogoIcon } from "./OpenAILogoIcon.svg"
export { ReactComponent as GreenLogoIcon } from "./GreenLogoIcon.svg"
export { ReactComponent as VinninALogoIcon } from "./VinninALogoIcon.svg"
export { ReactComponent as EditIcon } from "./EditIcon.svg"
export { ReactComponent as TrashIcon } from "./TrashIcon.svg"