import React from "react"
import UserCard from "../userCard/UserCard"
import BotCard from "../botCard/BotCard"
import { Message } from "../../pages/ChatPage"

type ChatPropsType = {
  messages: Message[]
  sendMessage: (msg: string) => void
  loading: boolean | undefined
}

const Chat = ({ messages, sendMessage, loading }: ChatPropsType) => {
  return (
    <div className="chat-messages">
      {messages?.map((message, index) => (
        <div key={index}>
          {message.user === 'You' ? (
            <UserCard
              text={message.text}
              date={message?.date}
              user={message.user}
            />
          ) : (
            <BotCard
              loading={loading}
              date={message?.date}
              text={message.text}
              questions={message.questions}
              user="Theo"
              sendMessage={sendMessage}
              sourceDocuments={
                message.sourceDocuments ? message.sourceDocuments : []
              }
            />
          )}
        </div>
      ))}
    </div>
  )
}
export default Chat
