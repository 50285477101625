import { truncate } from "../../../utils/helpers"
import React, { useState } from "react"
import "./styles.css"

type PageContentType = {
  content: string
}
const PageContent = ({ content }: PageContentType) => {
  const [showFullDescription, setFullDescription] = useState(false)

  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription)
  }
  const description = showFullDescription ? content : truncate(content, 185)
  return (
    <div className="modal-content-source-content">
      <div className="modal-content-source-descr">{description}</div>
      <div onClick={showFullDescriptionHandler} role="button" className="modal-content-source-read-btn">
        Read {showFullDescription ? "Less" : "More"}
      </div>
    </div>
  )
}
export default PageContent
