import React from "react"
import "./styles.css"
import { UserIcon } from "../../assets/icons"
type UserCardProps = {
  text: string
  date: string | undefined
  user: string
}
const UserCard = ({ text, date, user }: UserCardProps) => {
  return (
    <div className="message-user">
      <div className="message-user-info">
        <UserIcon />
        <div className="message-user-title">
          <div className="name">{user}</div>
          <div className="date">{date}</div>
        </div>
      </div>
      <div className="message-user-text">{text}</div>
    </div>
  )
}
export default UserCard
