import React from "react"
import { CloseIcon } from "../../assets/icons"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Modal from "react-modal"
import { modalShareWithFriendsLabels } from "../../utils/labels"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    width: "300px",
    height: "200px",
  },
  overlay: {
    zIndex: "10",
  },
}

type ShareWithFriendsModalPropsType = {
  modalIsOpen: boolean
  closeModal: () => void
  shareUrl: string
}

const ShareWithFriendsModal = ({
  modalIsOpen,
  closeModal,
  shareUrl,
}: ShareWithFriendsModalPropsType) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="modal-header">
        <div className="modal-header-title">
          {modalShareWithFriendsLabels.TITLE}
        </div>
        <div
          onClick={closeModal}
          role="button"
          className="modal-content-source-close-btn"
        >
          <CloseIcon />
        </div>
      </div>
      <div className="modal-content">
        <div className="modal-content-share-links">
          <TwitterShareButton
            url={shareUrl}
            title={modalShareWithFriendsLabels.TA}
          >
            <XIcon size={32} round />
          </TwitterShareButton>
          <FacebookShareButton
            url={shareUrl}
            title={modalShareWithFriendsLabels.TA}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <WhatsappShareButton
            url={shareUrl}
            title={modalShareWithFriendsLabels.TA}
            separator=":: "
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <LinkedinShareButton
            url={shareUrl}
            title={modalShareWithFriendsLabels.TA}
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
      </div>
    </Modal>
  )
}
export default ShareWithFriendsModal
