import React, { useEffect, useRef, useState } from "react"
import { getFormattedDateTimeNow } from "../utils/helpers"
import TheoSidebar from "../temp/TheoSidebar"
import { isMobile } from "react-device-detect"
import { LogoIcon } from "../temp/icons"
import { chatLabels, dummy, headerLabels, messageLabels } from "../utils/labels"
import { InfoIcon, ShareIcon, EditIcon, TrashIcon } from "../assets/icons"
import Chat from "../components/chat/Chat"
import ShareWithFriendsModal from "../components/shareWithFriendsModal/ShareWithFriendsModal"
import TheoSidebarModal from "../temp/TheoSidebarModal"
import InfoModal from "../components/infoModal/InfoModal"
import { useAuth0 } from "@auth0/auth0-react"
import { hotjar } from "react-hotjar"

const wsURL = process.env.REACT_APP_API_SERVER_WS || ""

type Metadata = {
  page: number
  document_name: string
}
export type SourceDocuments = {
  metadata: Metadata
  pageContent: string
}

export type Message = {
  text: string
  user?: string
  date?: string
  sourceDocuments?: SourceDocuments[]
  questions?: string[]
}

const data = [
  {
    title: "What is CSRD?",
    text: "What are the common challenges that companies face in aligning their reporting processes with CSRD requirements?"
  },
  {
    title: "Are there specific technologies?",
    text: "Are there specific technologies or tools that companies can use to streamline their data collection and reporting processes for CSRD compliance?"
  },
  {
    title: "What are some of the key features?",
    text: "What are some of the key features or functionalities that sustainability management software typically offers?"
  },
  {
    title: "Are there any case studies?",
    text: "Are there any case studies or examples of companies successfully achieving these sustainability goals using this software?"
  },
]

const hjid = 3773925
const hjsv = 6
const ChatPage = () => {
  hotjar.initialize({ id: hjid, sv: hjsv })
  const shareUrl = window.location.href
  const ref = useRef<HTMLInputElement | null>(null)
  const [loading, setLoading] = useState<boolean | undefined>(false)
  const [messages, setMessages] = useState<Message[]>([])
  const [isConnectionOpen, setConnectionOpen] = React.useState(false)
  const [messageBody, setMessageBody] = React.useState("")
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [modalIsInfoOpen, setIsInfoOpen] = React.useState(false)
  const [modalTHIsOpen, setIsTHOpen] = React.useState(false)
  const [isActiveTab, setIsActiveTab] = useState(0)
  const [isActiveTextarea, setIsActiveTextarea] = useState(true)

  const handleClick = (id: any) => {
    setIsActiveTab(id !== isActiveTab ? id : "")
  }

  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
  function openInfoModal() {
    setIsInfoOpen(true)
  }
  function closeInfoModal() {
    setIsInfoOpen(false)
  }
  function openTHModal() {
    setIsTHOpen(true)
  }
  function closeTHModal() {
    setIsTHOpen(false)
  }
  const ws = useRef<WebSocket>()

  const sendMessage = (msg?: string | null) => {
    const messageState = msg ? msg : messageBody

    setMessages((messages) => [
      ...messages,
      {
        text: messageState,
        user: "You",
        date: getFormattedDateTimeNow(),
      },
      {
        text: "",
        user: "loading",
        date: "",
      },
    ])
    if (messageState && ws && ws.current && isConnectionOpen) {
      setLoading(true)
      ws?.current?.send(
        JSON.stringify({
          event: "msg",
          data: messageState,
        })
      )
      setMessageBody("")
      setIsActiveTextarea(false)
    }
  }

  React.useEffect(() => {
    ws.current = new WebSocket(wsURL)
    ws.current.onopen = () => {
      setConnectionOpen(true)
    }
    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data)
      const newMessage = {
        ...data,
        date: getFormattedDateTimeNow(),
      }
      setMessages((_messages) => {
        return _messages.length > 0
          ? _messages.map((m) => {
              if (m.date === "") {
                m = newMessage
              }
              return m
            })
          : [newMessage]
      })
      setLoading(false)
    }

    return () => {
      setLoading(false)
      ws?.current?.close()
    }
  }, [])
  const scrollToBottom = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])
  const { isAuthenticated } = useAuth0()

  const isAuthEnvOn = process.env.REACT_APP_AUTH0_DOMAIN
  return (
    <>
      <div className="temp-wrapper">
        <TheoSidebar openTHModal={openTHModal} />
        <div className="app">
          <header className="header">
            <div>
              {isMobile ? (
                <LogoIcon />
              ) : (
                <span className="breadcrumbs"> {headerLabels.ASSISTANT}</span>
              )}
            </div>
            {/* If this part of code won't be used in the nearest future -- just remove it and related code*/}
            {/* <div className="header-links">
              {isAuthEnvOn && !isAuthenticated && (
                <>
                  <SignupButton />
                  <LoginButton />
                </>
              )}
              {isAuthEnvOn && isAuthenticated && (
                <>
                  <LogoutButton />
                </>
              )}
              {!isAuthEnvOn && (
                <>
                  <div className="header-link">Log in</div>
                  <div className="header-link">Sign up</div>
                </>
              )}
            </div> */}
          </header>
          <div className="app-content">
            <aside className="sidebar">
              <div className="sidebar__content">
                <div className="sidebar__heading">
                  <p>Topics</p>
                  <p className="plus-icon">+</p>
                </div>
                <div className="sidebar__topics">
                  {data.map((item, id) => (
                    <div
                      key={id}
                      className={`card ${isActiveTab === id ? "active" : ""}`}
                      onClick={() => handleClick(id)}
                    >
                      <div className="flex-row">
                        <p className="card-title">{item.title}</p>
                        {isActiveTab === id ? (
                          <div className="icon-wrapper">
                            <EditIcon />
                            <TrashIcon />
                          </div>
                          ) : ""
                        }
                      </div>
                      <p className="card-text">{item.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </aside>

            <main className="main">
              <div className="chat">
                <div className="chat-title">
                  {chatLabels.TITLE}
                  <div
                    role="button"
                    onClick={openInfoModal}
                    className="chat-title-icon"
                  >
                    <InfoIcon />
                  </div>
                </div>
                <div className="chat-desc">{chatLabels.DESCRIPTION}</div>
              </div>
              <div className="messages-wrapper">
                <div className="messages-main">
                  <div className="messages-header">
                    <div>
                      <div className="messages-header-title">{dummy.TITLE}</div>
                      <div className="messages-header-desc">
                        {dummy.DESCRIPTION}
                      </div>
                    </div>
                    <div className="messages-header-icons"></div>
                  </div>
                  <div className="messages">
                    <Chat
                      messages={messages}
                      sendMessage={sendMessage}
                      loading={loading}
                    />
                    <div ref={ref} />
                  </div>
                </div>
                <div className="send-message">
                  {isActiveTextarea && (
                    <textarea
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault()
                          sendMessage(null)
                        }
                      }}
                      id="message"
                      disabled={loading}
                      className="message-textarea"
                      placeholder={messageLabels.WRITE_SOMETHING}
                      value={messageBody}
                      onChange={(e) => setMessageBody(e.target.value)}
                    />
                  )}
                  {!isActiveTextarea && (
                    <div className="follow-up-block">
                      <div className="flex-column-centered">
                        <p>Choose if you want to ask a follow-up question or create a new topic below</p>
                        <div className="btn-wrapper">
                          <button
                            className="btn primary-btn"
                            onClick={() => setIsActiveTextarea(true)}
                          >
                            {messageLabels.ASK_QUESTIONS}
                          </button>
                          <button
                            className="btn secondary-btn"
                            onClick={() => window.location.reload()}
                          >
                            {messageLabels.NEW_TOPIC}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="buttons">
                    <div className="btn-wrapper">
                      <button className="btn share-btn" onClick={openModal}>
                        <ShareIcon />
                        {messageLabels.SHARE_WITH_FRIENDS}
                      </button>
                      <button
                        className="btn secondary-btn items-center"
                        onClick={() => window.location.reload()}
                      >
                        <EditIcon />
                        {messageLabels.CREATE_NEW_TOPIC}
                      </button>
                    </div>
                    <button
                      className="btn send-btn"
                      onClick={() => sendMessage(null)}
                      disabled={loading}
                    >
                      {messageLabels.SEND}
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <ShareWithFriendsModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          shareUrl={shareUrl}
        />
      </div>
      <TheoSidebarModal modalIsOpen={modalTHIsOpen} closeModal={closeTHModal} />
      <InfoModal modalIsOpen={modalIsInfoOpen} closeModal={closeInfoModal} />
    </>
  )
}
export default ChatPage
