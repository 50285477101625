import React from "react"
import { CloseIcon } from "../assets/icons"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Modal from "react-modal"
import banner from '../assets/img/banner.png'

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    width: "70%",
    height: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: "10",
  },
}

type TheoSidebarModalPropsType = {
  modalIsOpen: boolean
  closeModal: () => void
}

const TheoSidebarModal = ({
  modalIsOpen,
  closeModal,
}: TheoSidebarModalPropsType) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      id="learn-more-about-esg"
    >
      <div className="modal-header">
        <div className="modal-header-title">Learn more about ESG reporting</div>
        <div
          onClick={closeModal}
          role="button"
          className="modal-content-source-close-btn"
        >
          <CloseIcon />
        </div>
      </div>
      <div className="modal-content">
        <div className="th-modal-content">
          <div className="th-modal-banner">
            <img className="th-modal-img" src={banner} alt="image"/>
          </div>
          <div className="th-modal-info">
            <div>
              <div className="th-modal-info-title">Become compliant with <a href="https://theo.ai/" target="_blank" rel="noreferrer" >Theo.ai</a></div>
              <div className="th-modal-info-desc">
                Be it through our smart software, our dedicated team or innovative proprietary models Theo will support. Use our software to collaborate and report or receive the CSRD guidance you need to make reporting easier and clearer.
              </div>
              <div className="th-modal-info-list">
                <ul>
                  <li>
                    AI tools that guides and supports you
                  </li>
                  <li>
                    Powerful reporting software that empowers you
                  </li>
                  <li>
                    A transparent business model that aligns with your business goals
                  </li>
                </ul>
              </div>
            </div>
            <div className="th-modal-info-form">
              <div className="th-modal-info-form-title">Get in touch!</div>
              <div className="th-modal-info-form-link">
                <a
                  href="https://theo.ai/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="th-modal-info-form-btn"
                >
                    Contact us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default TheoSidebarModal
